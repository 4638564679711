import {Parameter, Representative, VatType} from "../../../../corelogic/models/parameter";
import {Customer} from "../../../../corelogic/models/customer";
import {Society} from "../../../../corelogic/models/society";
import {Invoice, InvoiceFilter, InvoiceTotals} from "../../../../corelogic/models/invoice";
import {getDateFromMilliseconds, getMillisecondsFromDate} from "./utils";

export interface InvoiceApiWithCountResponse {
    count: number
    invoices: InvoiceApiResponse[]
}

export interface InvoiceApiResponse {
    id: number
    society?: Society
    isCreditNote?: boolean
    sodiwinNumInvoice?: string
    reference?: string
    creationDate?: number
    billingDate?: number
    invoiceAddressBusinessName?: string
    invoiceAddress1?: string
    invoiceAddress2?: string
    invoiceAddress3?: string
    invoiceAddressZipCode1?: string
    invoiceAddressZipCode2?: string
    invoiceAddressCity?: string
    invoiceAddressCountry?: Parameter
    billedCustomer?: Customer
    saleRepresentative?: Representative
    totals?: InvoiceTotalsApi
    userLogin?: string
    dueDate?: number
    totalPaid?: number
}


export const mapInvoiceApiResponseToInvoice = (invoiceApiResponse: InvoiceApiResponse): Invoice => {
    return {
        id: invoiceApiResponse.id,
        society: invoiceApiResponse.society,
        isCreditNote: invoiceApiResponse.isCreditNote,
        sodiwinNumInvoice: invoiceApiResponse.sodiwinNumInvoice,
        reference: invoiceApiResponse.reference,
        creationDate: getDateFromMilliseconds(invoiceApiResponse.creationDate),
        billingDate: getDateFromMilliseconds(invoiceApiResponse.billingDate),
        invoiceAddressBusinessName: invoiceApiResponse.invoiceAddressBusinessName,
        invoiceAddress1: invoiceApiResponse.invoiceAddress1,
        invoiceAddress2: invoiceApiResponse.invoiceAddress2,
        invoiceAddress3: invoiceApiResponse.invoiceAddress3,
        invoiceAddressZipCode1: invoiceApiResponse.invoiceAddressZipCode1,
        invoiceAddressZipCode2: invoiceApiResponse.invoiceAddressZipCode2,
        invoiceAddressCity: invoiceApiResponse.invoiceAddressCity,
        invoiceAddressCountry: invoiceApiResponse.invoiceAddressCountry,
        billedCustomer: invoiceApiResponse.billedCustomer,
        saleRepresentative: invoiceApiResponse.saleRepresentative,
        totals: invoiceApiResponse.totals ? mapInvoiceTotalsApiToInvoiceTotals(invoiceApiResponse.totals) : undefined,
        userLogin: invoiceApiResponse.userLogin,
        dueDate: getDateFromMilliseconds(invoiceApiResponse.dueDate),
        totalPaid: invoiceApiResponse.totalPaid
    }
}

export const mapInvoiceTotalsApiToInvoiceTotals = (invoiceTotalsApi: InvoiceTotalsApi): InvoiceTotals => {
    return {
        discount: invoiceTotalsApi.discount,
        totalGrossTaxExcluded: invoiceTotalsApi.totalGrossTaxExcluded,
        shippingCostsVat: invoiceTotalsApi.shippingCostsVat,
        totalShippingCosts: invoiceTotalsApi.totalShippingCosts,
        totalVat: invoiceTotalsApi.totalVat,
        totalTaxExcluded: invoiceTotalsApi.totalTaxExcluded,
        totalWithTax: invoiceTotalsApi.totalWithTax
    };
};

export interface InvoiceTotalsApi {
    discount?: number
    totalGrossTaxExcluded?: number
    shippingCostsVat?: VatType
    totalShippingCosts?: number
    totalVat?: number
    totalTaxExcluded?: number
    totalWithTax?: number
}

export interface InvoiceFilterApi {
    sni?: string
    search?: string
    bd?: number
    bcc?: string
    osdt?: string
    osdn?: string
    ic?: string
    se?:boolean
}

export const mapInvoiceFilterToInvoiceFilterApi = (invoiceFilter: InvoiceFilter): InvoiceFilterApi => {
    return {
        sni: invoiceFilter.sodiwinNumInvoice,
        search: invoiceFilter.searchInput,
        bd: getMillisecondsFromDate(invoiceFilter.billingDate),
        bcc: invoiceFilter.billedCustomerCode,
        osdt: invoiceFilter.originalSalesDocumentType,
        osdn: invoiceFilter.originalSalesDocumentNum,
        ic: invoiceFilter.itemCode,
        se:invoiceFilter.settledOnly
    }
}