import {useIntl} from "react-intl";
import {getMessageDescriptor} from "../../../../../../i18n/messages";
import {Grid} from "@mui/material";
import React from "react";
import ReturnButton from "../../../Utils/ReturnButton";
import {DividingHR} from "../../../Utils/DividingHR";
import {TextValueField, TextValueFieldDate, TextValueFieldMonetary} from "../../../Utils/TextValueField";
import {Invoice} from "../../../../../../corelogic/models/invoice";

interface InvoiceDetailPageHeaderProps {
    invoice: Invoice
    isCustomer?: boolean
}

export const InvoiceDetailPageHeader = (props: InvoiceDetailPageHeaderProps) => {
    const {invoice, isCustomer} = props
    const intl = useIntl()

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center" p={1}>
                <Grid item>
                    <Grid container alignItems="center">
                        <Grid item>
                            <ReturnButton/>
                        </Grid>
                        <Grid item mx={2}>
                            <DividingHR
                                subContent
                                style={{height: "30px", width: 1, margin: 0}}/>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column">
                                <Grid item>
                                    <TextValueField
                                        style={{
                                            fontWeight: "bolder"
                                        }}
                                        text={intl.formatMessage(getMessageDescriptor("invoiceDetailsPageHeaderTitle"))}
                                        value={invoice.sodiwinNumInvoice ?? ""}/>
                                </Grid>
                                {!isCustomer && <Grid item>
                                    <TextValueField style={{fontWeight: "bolder"}}
                                                    text={intl.formatMessage(getMessageDescriptor("invoiceDetailsPageHeaderBilledCustomer"))}
                                                    value={invoice.billedCustomer?.code || ""}/>
                                </Grid>}
                            </Grid>
                        </Grid>
                        <Grid item mx={2}>
                            <DividingHR
                                subContent
                                style={{height: "30px", width: 1, margin: 0}}/>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column">
                                <Grid item>
                                    <TextValueFieldDate
                                        style={{
                                            fontWeight: "bolder"
                                        }}
                                        // text={intl.formatMessage(getMessageDescriptor("invoiceDetailsPageHeaderTitle"))}
                                        text="Date d'échéance"
                                        value={invoice.dueDate}/>
                                </Grid>
                               <Grid item>
                                    <TextValueFieldMonetary style={{fontWeight: "bolder"}}
                                                    // text={intl.formatMessage(getMessageDescriptor("invoiceDetailsPageHeaderBilledCustomer"))}
                                                    text="Total réglé"
                                                    value={invoice.totalPaid}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}