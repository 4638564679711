import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {ItemsListPageHeader} from "./ItemsListPageHeader";
import {Alert, CircularProgress, Collapse, Grid, Pagination, Paper} from "@mui/material";
import {Item, ItemFilter} from "../../../../../../corelogic/models/item";
import {useDispatch, useSelector} from "react-redux";
import {getItemAllFormSelector, getItemsSelector} from "../../../../../../store/selectors/itemsSelectors";
import {
    addUserItemFavorites,
    removeUserItemFavorites,
    retrieveAllItemDataFormFieldsForFilter,
    retrieveAllItemsFiltered
} from "../../../../../../corelogic/usecase/items/itemsActions";
import '../../../Style/Pages/ItemPages/_ItemListPage.scss';
import '../../../Style/Pages/ItemPages/_ItemPage.scss';
import {useIntl} from "react-intl";
import {ItemCard} from './ItemCard';
import {APP_ACTIONS_TYPES} from "../../../../../../store/AppActionsTypes";
import {getUserLoggedSelector} from "../../../../../../store/selectors/sessionSelector";
import {UserRoleCode} from "../../../../../../corelogic/models/user";
import ItemFilterAccordion from "./ItemFilterAccordion";
import {ScrollUpButton} from "../../../Utils/ScrollUpButton";
import {getMessageDescriptor} from "../../../../../../i18n/messages";

export const ItemsListPage = () => {
    const dispatch = useDispatch()
    const {data, fetching, count: dataCount} = useSelector(getItemsSelector)
    const {dataFormFields} = useSelector(getItemAllFormSelector)
    const {userLogged} = useSelector(getUserLoggedSelector)
    const intl = useIntl()
    const rootElement = document.documentElement
    const ITEMS_PER_PAGE = 24
    const [isVdc, setIsVdc] = useState(document.location.hostname === process.env.REACT_APP_CTX_CLI_VDC && userLogged?.role?.code === UserRoleCode.CUSTOMER ? true : undefined)
    const [page, setPage] = React.useState(1)
    const [filterCount, setFilterCount] = useState(isVdc ? 4 : 3)
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
        scrollToTop()
    }
    const [toggleFilters, setToggleFilters] = useState<boolean>(false)
    const [itemFilter, setItemFilter] = useState<ItemFilter>({
        search: "",
        familyCodes: [],
        superFamilyCodes: [],
        brandCodes: [],
        geographicalOriginCodes: [],
        authorizationToSellOnly: userLogged?.role?.code === UserRoleCode.COMMERCIAL || userLogged?.role?.code === UserRoleCode.CUSTOMER,
        favorite: isVdc,
        isManagedInStock: undefined,
        isSold: true,
        isPurchased: undefined,
        isOrganic: undefined,
        isBlocked: false,
        isPublishedInTheCatalog: true,
        isExportECommerce: undefined,
        isOrderSchedule: undefined,
        familyIds: [],
        superFamilyIds: [],
        brandIds: [],
        geographicalOriginIds: []
    })

    useEffect(() => {
        setIsVdc(document.location.hostname === process.env.REACT_APP_CTX_CLI_VDC && userLogged?.role?.code === UserRoleCode.CUSTOMER ? true : undefined)
    }, [userLogged])

    useEffect(() => {
        if (userLogged && userLogged.role?.code === UserRoleCode.CUSTOMER)
            dispatch<any>(retrieveAllItemDataFormFieldsForFilter(userLogged.linkedCustomer?.id))
        else
            dispatch<any>(retrieveAllItemDataFormFieldsForFilter())
    }, [dispatch, userLogged])

    useEffect(() => {
        dispatch(retrieveAllItemsFiltered(itemFilter, {size: ITEMS_PER_PAGE, page: page}))
        return () => dispatch<any>({type: APP_ACTIONS_TYPES.items.ITEM_RESET_DATA})
    }, [dispatch, itemFilter, page])

    const scrollToTop = () => {
        rootElement.scrollTo({top: 0, behavior: "auto"})
    }

    const handleToggleFavorite = (item: Item) => {
        if (item.favorite)
            dispatch<any>(removeUserItemFavorites(item.id))
        else
            dispatch<any>(addUserItemFavorites(item.id))
    }

    const getInputValue = useCallback((inputValue: string) => {
        setItemFilter(prev => ({...prev, search: inputValue}))
    }, []);

    const handleApplyAllFilters = (itemFilters: ItemFilter, filterCount: number) => {
        setItemFilter({
            ...itemFilter,
            brandCodes: itemFilters.brandCodes,
            familyCodes: itemFilters.familyCodes,
            superFamilyCodes: itemFilters.superFamilyCodes,
            favorite: itemFilters.favorite,
            geographicalOriginCodes: itemFilters.geographicalOriginCodes,
            isSold: itemFilters.isSold,
            isBlocked: itemFilters.isBlocked,
            isOrganic: itemFilters.isOrganic,
            isManagedInStock: itemFilters.isManagedInStock,
            isPurchased: itemFilters.isPurchased,
            isPublishedInTheCatalog: itemFilters.isPublishedInTheCatalog,
            isExportECommerce: itemFilters.isExportECommerce,
            isOrderSchedule: itemFilters.isOrderSchedule,
            brandIds:itemFilters.brandIds,
            familyIds:itemFilters.familyIds,
            superFamilyIds:itemFilters.superFamilyIds,
            geographicalOriginIds:itemFilters.geographicalOriginIds
        })
        setFilterCount(filterCount)
        setPage(1)
    }

    const handleResetAllFilters = () => {
        setItemFilter({
            ...itemFilter,
            familyCodes: [],
            superFamilyCodes: [],
            brandCodes: [],
            geographicalOriginCodes: [],
            authorizationToSellOnly: userLogged?.role?.code === UserRoleCode.COMMERCIAL || userLogged?.role?.code === UserRoleCode.CUSTOMER,
            favorite: isVdc,
            isManagedInStock: undefined,
            isSold: true,
            isPurchased: undefined,
            isOrganic: undefined,
            isBlocked: false,
            isPublishedInTheCatalog: true,
            isExportECommerce: undefined,
            isOrderSchedule: undefined
        })
        setFilterCount(2)
        setPage(1)
    }

    const getToggleFilterBooleanState = (toggleFiltersValue: boolean) => {
        if (toggleFiltersValue) {
            setToggleFilters(true)
        } else {
            setToggleFilters(false)
        }
    }

    return (
        <>
            <Paper elevation={3} sx={{backgroundColor: "common.main", position: "sticky", top: "77px", zIndex: 1000}}>
                <ItemsListPageHeader productCount={dataCount} onChange={getInputValue}
                                     filterCount={filterCount} getToggleFiltersState={getToggleFilterBooleanState}/>
            </Paper>
            <Grid container minHeight="85vh">
                <Collapse orientation="horizontal" in={toggleFilters} sx={{borderRadius: "1%", px: 2, py: 1}}>
                    <ItemFilterAccordion dataFormFields={dataFormFields}
                                         onClickApplyFilters={handleApplyAllFilters}
                                         onClickResetAllFilters={handleResetAllFilters}
                                         withOrderSchedule={userLogged?.role?.code === UserRoleCode.CUSTOMER}/>
                </Collapse>
                {fetching ?
                    <Grid item flexGrow={1}>
                        <Grid container justifyContent="center" alignItems="center" height="100%">
                            <CircularProgress size={60}/>
                        </Grid>
                    </Grid>
                    : dataCount > 0 ?
                        <Grid item md height="inherit">
                            <Grid container justifyContent="center" height="100%">
                                {data.map((item) => {
                                    return (
                                        <Grid item key={item.id}>
                                            <ItemCard key={item.id} data={item} onToggleFavorite={handleToggleFavorite}/>
                                        </Grid>
                                    )
                                })}
                                {dataCount > 0 &&
                                    <Grid item container p={3} justifyContent="center" alignSelf="flex-end">
                                        <Paper sx={{p: 1}}>
                                            <Pagination count={Math.ceil(dataCount / ITEMS_PER_PAGE)}
                                                        boundaryCount={1}
                                                        page={page}
                                                        onChange={handleChange} color="primary"/>
                                        </Paper>
                                    </Grid>}
                            </Grid>
                        </Grid>
                        : (itemFilter.superFamilyCodes.length !== 0 || itemFilter.familyCodes.length !== 0 || itemFilter.brandCodes.length !== 0 || itemFilter.favorite || itemFilter.geographicalOriginCodes.length !== 0 || itemFilter.search.length > 0) && data.length === 0 &&
                        <Grid item md height="inherit" justifyContent="center" alignItems="flex-start" mt={1}>
                            <Alert severity="info" sx={{alignItems: "center"}}>
                                {intl.formatMessage(getMessageDescriptor("itemListFilterNotFound"))}
                            </Alert>
                        </Grid>
                }
            </Grid>
            <ScrollUpButton/>
        </>
    )
}
