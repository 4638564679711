import {Item} from "corelogic/models/item";
import {
    brandsData,
    geographicalOriginData,
    itemFamilyData,
    packagingTypeData,
    superItemFamilyData,
    unitsData,
    vatData
} from "./parameters";
import {Society} from "../../../../corelogic/models/society";
import {Depot} from "../../../../corelogic/models/depot";
import {EnumData, Parameter} from "corelogic/models/parameter";
import {BarecodeType, DimensionUnit, enumToEnumData, OrderStatus, UserStatus, WeightUnit} from "./enums";
import {DataGraphOrdersCountInterface, Order, OrderComment, OrderLine} from "../../../../corelogic/models/order";
import {User} from "corelogic/models/user";
import {Customer} from "../../../../corelogic/models/customer";

export const dataGraphOrder: DataGraphOrdersCountInterface[] = [
    {
        dateOrder: '01/08',
        ordersCount: 2400,
    },
    {
        dateOrder: '02/08',
        ordersCount: 1398,
    },
    {
        dateOrder: '03/08',
        ordersCount: 9800,
    },
    {
        dateOrder: '04/08',
        ordersCount: 3908,
    },
    {
        dateOrder: '05/08',
        ordersCount: 4800,
    },
    {
        dateOrder: '06/08',
        ordersCount: 3800,
    },
    {
        dateOrder: '07/08',
        ordersCount: 4300,
    },
]
export const orderStatusData: EnumData[] = [
    {value: "CREATION", label: "Création de commande"},
    {value: "NEW", label: "Nouvelle"},
    {value: "AWAITING_VALIDATION", label: "En attente de validation"},
    {value: "DEALING_WITH", label: "Prise en charge"},
    {value: "BLOCKED", label: "Bloquée"},
    {value: "PARTIALLY_SHIPPED", label: "Expédiée partielle"},
    {value: "SHIPPED", label: "Expédiée"},
    {value: "PARTIALLY_BILLED", label: "Facturée partielle"},
    {value: "BILLED", label: "Facturée"},
    {value: "CANCELED", label: "Annulée"},
    {value: "DELETED", label: "Supprimée"}
]

export const customerCategoriesData: Parameter[] = [
    {id: 1, code: "CU1", label: "EUR"},
    {id: 1, code: "CU2", label: "DOLLAR"},
    {id: 1, code: "CU3", label: "Currency 3"},
    {id: 1, code: "CU4", label: "Currency 4"},
    {id: 1, code: "CU5", label: "Currency 5"},
]

export const representativesData: Parameter[] = [
    {id: 1, code: "REPR1", label: "Jean Claude"},
    {id: 2, code: "REPR2", label: "Francis Lalane"},
    {id: 3, code: "REPR3", label: "Albert Dumas"}
]

export const statusData: EnumData[] = [
    {value: "ACTIVE", label: "Active"},
    {value: "INACTIVE", label: "Inactive"},
    {value: "AWAITING_CREATION", label: "Awaiting creation"}
]

export const rolesData: Parameter[] = [
    {id: 1, code: "ADMIN", label: "Administrator"},
    {id: 2, code: "COMME", label: "Commercial"},
    {id: 3, code: "CUSTO", label: "Customer"}
]

export const languagesData: Parameter[] = [
    {id: 1, code: "FRA", label: "Français"},
    {id: 2, code: "ITA", label: "Italien"},
    {id: 3, code: "ESP", label: "Espagnol"},
    {id: 4, code: "ENG", label: "Anglais"},
    {id: 5, code: "EST", label: "Estonien"},
]

export const countriesData: Parameter[] = [
    {id: 1, code: "FRA", label: "France"},
    {id: 2, code: "ITA", label: "Italie"},
    {id: 3, code: "ESP", label: "Espagne"},
    {id: 4, code: "ENG", label: "Angleterre"},
    {id: 5, code: "EST", label: "Estonie"},
]

export const currenciesData: Parameter[] = [
    {id: 1, code: "CU1", label: "EUR"},
    {id: 1, code: "CU2", label: "DOLLAR"},
    {id: 1, code: "CU3", label: "Currency 3"},
    {id: 1, code: "CU4", label: "Currency 4"},
    {id: 1, code: "CU5", label: "Currency 5"},
]

export const societiesData: Society[] = [
    {
        id: 1, code: "S1", label: "Société 1"
    },
    {
        id: 2, code: "S2", label: "Société 2"
    }
]

export const depotsData: Depot[] = [
    {
        id: 1, code: "D1", label: "Dépôt 1", society: societiesData[0]
    },
    {
        id: 2, code: "D2", label: "Dépôt 2", society: societiesData[1]
    }
]

export const businessFormsData: Parameter[] = [
    {
        id: 1, code: "BF1", label: "Business Forms 1"
    },
    {
        id: 2, code: "BF2", label: "Business Forms 2"
    },
    {
        id: 1, code: "BF3", label: "Business Forms 3"
    }
]

export const customerDeliveryAddressesData = {
    1: [{
        id: 1,
        title: "Siège social",
        businessName: "Kebab Family",
        address1: "20 rue du kebab",
        address2: "Bat D",
        address3: "Cedex 22",
        zipCode1: "69003",
        zipCode2: "TSA 12",
        city: "LYON",
        country: countriesData[0]
    }, {
        id: 2,
        title: "Kebab Family Mermoz",
        businessName: "Kebab Family",
        address1: "10 rue de mermoz",
        address2: "Dépôt 1",
        address3: "Cedex 22",
        zipCode1: "69500",
        zipCode2: "TSA 12",
        city: "BRON",
        country: countriesData[0]
    }, {
        id: 3,
        title: "Kebab Family Garibaldi",
        businessName: "Kebab Family",
        address1: "5 avenue de Garibaldi",
        address2: "Dépôt 2",
        address3: "Cedex 55",
        zipCode1: "69003",
        zipCode2: "TSA 12",
        city: "LYON",
        country: countriesData[0]
    }, {
        id: 4,
        title: "Kebab Family Grenoble",
        businessName: "Kebab Family",
        address1: "69 rue de Grenoble",
        address2: "Bat A",
        address3: "Cedex 12",
        zipCode1: "38006",
        zipCode2: "TSA 12",
        city: "Grenoble",
        country: countriesData[0]
    }, {
        id: 5,
        title: "Kebab Family St Etienne",
        businessName: "Kebab Family",
        address1: "52 rue de St Etienne",
        address2: "Bat F",
        address3: "Cedex 55",
        zipCode1: "42000",
        zipCode2: "TSA 12",
        city: "St Etienne",
        country: countriesData[0]
    }, {
        id: 6,
        title: "Kebab Family Dépôt C8Q3",
        businessName: "Kebab Family",
        address1: "120 rue de Paris",
        address2: "Bat A",
        address3: "Cedex 22",
        zipCode1: "75007",
        zipCode2: "TSA 12",
        city: "Paris",
        country: countriesData[0]
    }, {
        id: 7,
        title: "Kebab Family Dépôt Marseille",
        businessName: "Kebab Family",
        address1: "60 rue de Marseille",
        address2: "Bat E",
        address3: "Cedex 77",
        zipCode1: "13000",
        zipCode2: "TSA 12",
        city: "Marseille",
        country: countriesData[0]
    }]
}

export const customersCategories: Parameter[] = [
    {
        id: 1,
        code: "CAT1",
        label: "Distribution"
    },
    {
        id: 2,
        code: "CAT2",
        label: "Category 2"
    },
    {
        id: 3,
        code: "CAT3",
        label: "Category 3"
    }
]

export const customersStatus: Parameter[] = [
    {
        id: 1,
        code: "STA1",
        label: "Status 1"
    },
    {
        id: 2,
        code: "STA2",
        label: "Status 2"
    },
    {
        id: 3,
        code: "STA3",
        label: "Status 3"
    }
]

export const customersRates: Parameter[] = [
    {
        id: 1,
        code: "RAT1",
        label: "Rate 1"
    },
    {
        id: 2,
        code: "RAT2",
        label: "Rate 2"
    },
    {
        id: 3,
        code: "RAT3",
        label: "Rate 3"
    }
]

export const customersData: Customer[] = [
    {
        id: 1,
        code: "code",
        society: societiesData[0],
        businessForm: businessFormsData[0],
        businessName: "TANG FRÈRES IMPORT-EXPORT",
        language: languagesData[0],
        currency: currenciesData[0],
        siret: "30624383300044",
        intraCommunityVAT: "Intra Vat",
        apeNaf: "ApeNaf",
        eanCode: "Ean",
        stockCapital: 1_225_568_954,
        rcs: "RCS",
        address1: "48 AVENUE D' IVRY",
        address2: "Address 1",
        address3: "Address 2",
        zipCode1: "75013",
        zipCode2: "Zip Code 2",
        city: "Paris",
        country: countriesData[0],
        phone: "01 49 60 56 78",
        fax: "Fax",
        mail: "tang-freres@gmail.com",
        iban: "145368789554351235413698548",
        bic: "BIC",
        category: customersCategories[0],
        accountingCode: vatData[1],
        rateCode: customersRates[0],
        status: customersStatus[0],
        saleRepresentative1: {id: 1, code: "SR1", label: "SR1"},
        blocked: false,
        deliveryAddresses: [],
        vatManagement: {value: "a", label: "a"}
    },
    {
        id: 2,
        code: "code",
        society: societiesData[0],
        businessForm: businessFormsData[1],
        businessName: "SOGEDIL",
        language: languagesData[0],
        currency: currenciesData[0],
        siret: "35153882200039",
        intraCommunityVAT: "Intra Vat 2",
        apeNaf: "ApeNaf 2",
        eanCode: "Ean 2",
        stockCapital: 3_154_320_855,
        rcs: "RCS 2",
        address1: "6/8, RUE NICOLAS APPERT",
        address2: "Address 1",
        address3: "Address 2",
        zipCode1: "77185",
        zipCode2: "Zip Code 2",
        city: "LOGNES",
        country: countriesData[0],
        phone: "01 64 62 98 88",
        fax: "Fax",
        mail: "Sogedil@gmail.com",
        iban: "145368789554351235413698548",
        bic: "BIC",
        category: customersCategories[0],
        status: customersStatus[1],
        accountingCode: vatData[1],
        rateCode: customersRates[1],
        blocked: false,
        deliveryAddresses: [],
        vatManagement: {value: "a", label: "a"}
    },
    {
        id: 3,
        code: "code",
        society: societiesData[0],
        businessForm: businessFormsData[1],
        businessName: "CHINA MARKET",
        language: languagesData[0],
        currency: currenciesData[0],
        siret: "35153882200039",
        intraCommunityVAT: "Intra Vat 2",
        apeNaf: "ApeNaf 2",
        eanCode: "Ean 2",
        stockCapital: 3_154_320_855,
        rcs: "RCS 2",
        address1: "CHEMIN DE LA GRANDE CAMPAGNE",
        address2: "PLAN DE CAMPAGNE",
        address3: "Address 2",
        zipCode1: "13480",
        zipCode2: "Zip Code 2",
        city: "Cabries",
        country: countriesData[0],
        phone: "04 42 46 34 46",
        fax: "Fax",
        mail: "sarlchinamarket@gmail.com",
        iban: "145368789554351235413698548",
        bic: "BIC",
        category: customersCategories[0],
        status: customersStatus[1],
        accountingCode: vatData[1],
        rateCode: customersRates[1],
        blocked: false,
        deliveryAddresses: [],
        vatManagement: {value: "a", label: "a"}
    },
    {
        id: 4,
        code: "code",
        society: societiesData[0],
        businessForm: businessFormsData[1],
        businessName: "DRAGON D'OR",
        language: languagesData[0],
        currency: currenciesData[0],
        siret: "35153882200039",
        intraCommunityVAT: "Intra Vat 2",
        apeNaf: "ApeNaf 2",
        eanCode: "Ean 2",
        stockCapital: 3_154_320_855,
        rcs: "RCS 2",
        address1: "26-28 RUE BANNELIER",
        address2: "PLAN DE CAMPAGNE",
        address3: "Address 2",
        zipCode1: "21000",
        zipCode2: "Zip Code 2",
        city: "Dijon",
        country: countriesData[0],
        phone: "03 80 30 88 88",
        fax: "Fax",
        mail: "lemarchedubonheur@sfr.fr",
        iban: "145368789554351235413698548",
        bic: "BIC",
        category: customersCategories[0],
        status: customersStatus[1],
        accountingCode: vatData[1],
        rateCode: customersRates[1],
        blocked: false,
        deliveryAddresses: [],
        vatManagement: {value: "a", label: "a"}
    }
]

export const usersData: User[] = [
    {
        id: 1,
        linkedCustomer: customersData[0],
        login: "TEST",
        lastName: "Test",
        firstName: "test",
        mail: 'test@test.com',
        defaultSociety: societiesData[0],
        defaultDepot: depotsData[0],
        status: enumToEnumData(UserStatus, UserStatus.ACTIVE),
        language: languagesData[0],
        password: "test",
        sendEmailAtOrderSubmission: false
    },
    {
        id: 2,
        linkedCustomer: customersData[1],
        login: "TEST2",
        lastName: "Test2",
        firstName: "test2",
        mail: 'test2@test2.com',
        defaultSociety: societiesData[1],
        defaultDepot: depotsData[1],
        status: enumToEnumData(UserStatus, UserStatus.ACTIVE),
        language: languagesData[0],
        password: "test2",
        sendEmailAtOrderSubmission: false
    },
    {
        id: 2,
        login: "TEST3",
        lastName: "Test3",
        firstName: "test3",
        mail: 'test3@test3.com',
        defaultSociety: societiesData[1],
        defaultDepot: depotsData[1],
        status: enumToEnumData(UserStatus, UserStatus.ACTIVE),
        language: languagesData[0],
        password: "test3",
        sendEmailAtOrderSubmission: false
    }
]

export const itemsData: Item[] = [{
    id: 1,
    code: "ART1",
    commercialDesignation: "Article 1 commercial",
    label: "Article 1 label",
    brand: brandsData[0],
    superFamily: superItemFamilyData[0],
    // family: itemFamilyData[0],
    geographicalOrigin: geographicalOriginData[0],
    packagings: [packagingTypeData[0]],
    degreeOfAlcohol: 7.02,
    density: 2,
    barcodeType: enumToEnumData(BarecodeType, BarecodeType.EAN13),
    barcode: "01234567890123",
    width: 12.2,
    height: 25.6,
    depth: 14,
    dimensionUnit: enumToEnumData(DimensionUnit, DimensionUnit.CM),
    byHowMuch: 12,
    packagePerLayer: 9,
    layerPerPallet: 3,
    netWeight: 1.5,
    grossWeight: 2,
    weightUnit: enumToEnumData(WeightUnit, WeightUnit.KG),
    orderUnit: unitsData[1],
    vatType: vatData[0],
    priceExTax: 55,
    picture: {},
    rateDetails: [],
    promotionalRateDetails: [],
}, {
    id: 2,
    code: "ART2",
    commercialDesignation: "Article 2 commercial",
    label: "Article 2 label",
    brand: brandsData[0],
    superFamily: superItemFamilyData[1],
    family: itemFamilyData[0],
    // geographicalOrigin: geographicalOriginData[0],
    packagings: [packagingTypeData[1], packagingTypeData[2]],
    degreeOfAlcohol: 3.21,
    density: 4,
    barcodeType: enumToEnumData(BarecodeType, BarecodeType.EAN13),
    barcode: "77224867591134",
    width: 11.5,
    height: 20.2,
    depth: 11,
    dimensionUnit: enumToEnumData(DimensionUnit, DimensionUnit.CM),
    byHowMuch: 20,
    packagePerLayer: 10,
    layerPerPallet: 6,
    netWeight: 3.5,
    grossWeight: 8,
    weightUnit: enumToEnumData(WeightUnit, WeightUnit.KG),
    orderUnit: unitsData[2],
    vatType: vatData[1],
    priceExTax: 64,
    picture: {},
    rateDetails: [],
    promotionalRateDetails: [],
},
    {
        id: 3,
        code: "ART3",
        commercialDesignation: "Article 3 commercial",
        label: "Article 3 label",
        brand: brandsData[1],
        // superFamily: superItemFamilyData[1],
        family: itemFamilyData[1],
        geographicalOrigin: geographicalOriginData[1],
        packagings: [packagingTypeData[2], packagingTypeData[3]],
        degreeOfAlcohol: 7.02,
        density: 2,
        barcodeType: enumToEnumData(BarecodeType, BarecodeType.EAN13),
        barcode: "01234567890123",
        width: 12.2,
        height: 25.6,
        depth: 14,
        dimensionUnit: enumToEnumData(DimensionUnit, DimensionUnit.CM),
        byHowMuch: 12,
        packagePerLayer: 9,
        layerPerPallet: 3,
        netWeight: 1.5,
        grossWeight: 2,
        weightUnit: enumToEnumData(WeightUnit, WeightUnit.KG),
        orderUnit: unitsData[0],
        vatType: vatData[1],
        priceExTax: 32,
        picture: {},
        rateDetails: [],
        promotionalRateDetails: [],
    }, {
        id: 4,
        code: "ART4",
        commercialDesignation: "Article 4 commercial",
        label: "Article 4 label",
        // brand: brandsData[1],
        superFamily: superItemFamilyData[1],
        family: itemFamilyData[1],
        geographicalOrigin: geographicalOriginData[0],
        packagings: [packagingTypeData[0], packagingTypeData[2]],
        degreeOfAlcohol: 3.21,
        density: 4,
        barcodeType: enumToEnumData(BarecodeType, BarecodeType.EAN13),
        barcode: "77224867591134",
        width: 11.5,
        height: 20.2,
        depth: 11,
        dimensionUnit: enumToEnumData(DimensionUnit, DimensionUnit.CM),
        byHowMuch: 20,
        packagePerLayer: 10,
        layerPerPallet: 6,
        netWeight: 3.5,
        grossWeight: 8,
        weightUnit: enumToEnumData(WeightUnit, WeightUnit.KG),
        orderUnit: unitsData[0],
        vatType: vatData[1],
        priceExTax: 17.23,
        picture: {},
        rateDetails: [],
        promotionalRateDetails: [],
    },
    {
        id: 5,
        code: "ART5",
        commercialDesignation: "Article 5 commercial",
        label: "Article 5 label",
        brand: brandsData[0],
        superFamily: superItemFamilyData[1],
        family: itemFamilyData[2],
        geographicalOrigin: geographicalOriginData[1],
        packagings: [packagingTypeData[1], packagingTypeData[2]],
        degreeOfAlcohol: 7.02,
        density: 2,
        barcodeType: enumToEnumData(BarecodeType, BarecodeType.EAN13),
        barcode: "01234567890123",
        width: 12.2,
        height: 25.6,
        depth: 14,
        dimensionUnit: enumToEnumData(DimensionUnit, DimensionUnit.CM),
        byHowMuch: 12,
        packagePerLayer: 9,
        layerPerPallet: 3,
        netWeight: 1.5,
        grossWeight: 2,
        weightUnit: enumToEnumData(WeightUnit, WeightUnit.KG),
        orderUnit: unitsData[1],
        vatType: vatData[0],
        priceExTax: 46.50,
        picture: {},
        rateDetails: [],
        promotionalRateDetails: [],
    }, {
        id: 6,
        code: "ART6",
        commercialDesignation: "Article 6 commercial",
        label: "Article 6 label",
        brand: brandsData[1],
        superFamily: superItemFamilyData[2],
        family: itemFamilyData[1],
        geographicalOrigin: geographicalOriginData[0],
        packagings: [packagingTypeData[1], packagingTypeData[2]],
        degreeOfAlcohol: 3.21,
        density: 4,
        barcodeType: enumToEnumData(BarecodeType, BarecodeType.EAN13),
        barcode: "77224867591134",
        width: 11.5,
        height: 20.2,
        depth: 11,
        dimensionUnit: enumToEnumData(DimensionUnit, DimensionUnit.CM),
        byHowMuch: 20,
        packagePerLayer: 10,
        layerPerPallet: 6,
        netWeight: 3.5,
        grossWeight: 8,
        weightUnit: enumToEnumData(WeightUnit, WeightUnit.KG),
        orderUnit: unitsData[1],
        vatType: vatData[1],
        priceExTax: 23,
        picture: {},
        rateDetails: [],
        promotionalRateDetails: [],
    },
    {
        id: 7,
        code: "ART7",
        commercialDesignation: "Article 7 commercial",
        label: "Article 7 label",
        brand: brandsData[0],
        superFamily: superItemFamilyData[0],
        family: itemFamilyData[2],
        geographicalOrigin: geographicalOriginData[0],
        packagings: [packagingTypeData[1], packagingTypeData[2]],
        degreeOfAlcohol: 7.02,
        density: 2,
        barcodeType: enumToEnumData(BarecodeType, BarecodeType.EAN13),
        barcode: "01234567890123",
        width: 12.2,
        height: 25.6,
        depth: 14,
        dimensionUnit: enumToEnumData(DimensionUnit, DimensionUnit.CM),
        byHowMuch: 12,
        packagePerLayer: 9,
        layerPerPallet: 3,
        netWeight: 1.5,
        grossWeight: 2,
        weightUnit: enumToEnumData(WeightUnit, WeightUnit.KG),
        orderUnit: unitsData[2],
        vatType: vatData[0],
        priceExTax: 17.70,
        picture: {},
        rateDetails: [],
        promotionalRateDetails: [],
    }, {
        id: 8,
        code: "ART8",
        commercialDesignation: "Article 8 commercial",
        label: "Article 8 label",
        brand: brandsData[1],
        superFamily: superItemFamilyData[1],
        family: itemFamilyData[1],
        geographicalOrigin: geographicalOriginData[1],
        packagings: [packagingTypeData[1], packagingTypeData[2]],
        degreeOfAlcohol: 3.21,
        density: 4,
        barcodeType: enumToEnumData(BarecodeType, BarecodeType.EAN13),
        barcode: "77224867591134",
        width: 11.5,
        height: 20.2,
        depth: 11,
        dimensionUnit: enumToEnumData(DimensionUnit, DimensionUnit.CM),
        byHowMuch: 20,
        packagePerLayer: 10,
        layerPerPallet: 6,
        netWeight: 3.5,
        grossWeight: 8,
        weightUnit: enumToEnumData(WeightUnit, WeightUnit.KG),
        orderUnit: unitsData[2],
        vatType: vatData[1],
        priceExTax: 86.50,
        picture: {},
        rateDetails: [],
        promotionalRateDetails: [],
    },
    {
        id: 9,
        code: "ART9",
        commercialDesignation: "Article 9 commercial",
        label: "Article 9 label",
        brand: brandsData[1],
        superFamily: superItemFamilyData[0],
        family: itemFamilyData[0],
        geographicalOrigin: geographicalOriginData[0],
        packagings: [packagingTypeData[1], packagingTypeData[2]],
        degreeOfAlcohol: 7.02,
        density: 2,
        barcodeType: enumToEnumData(BarecodeType, BarecodeType.EAN13),
        barcode: "01234567890123",
        width: 12.2,
        height: 25.6,
        depth: 14,
        dimensionUnit: enumToEnumData(DimensionUnit, DimensionUnit.CM),
        byHowMuch: 12,
        packagePerLayer: 9,
        layerPerPallet: 3,
        netWeight: 1.5,
        grossWeight: 2,
        weightUnit: enumToEnumData(WeightUnit, WeightUnit.KG),
        orderUnit: unitsData[2],
        vatType: vatData[0],
        priceExTax: 10.99,
        picture: {},
        rateDetails: [],
        promotionalRateDetails: [],
    }, {
        id: 10,
        code: "ART10",
        commercialDesignation: "Article 10 commercial",
        label: "Article 10 label",
        brand: brandsData[1],
        superFamily: superItemFamilyData[1],
        family: itemFamilyData[2],
        geographicalOrigin: geographicalOriginData[1],
        packagings: [packagingTypeData[1], packagingTypeData[2]],
        degreeOfAlcohol: 3.21,
        density: 4,
        barcodeType: enumToEnumData(BarecodeType, BarecodeType.EAN13),
        barcode: "77224867591134",
        width: 11.5,
        height: 20.2,
        depth: 11,
        dimensionUnit: enumToEnumData(DimensionUnit, DimensionUnit.CM),
        byHowMuch: 20,
        packagePerLayer: 10,
        layerPerPallet: 6,
        netWeight: 3.5,
        grossWeight: 8,
        weightUnit: enumToEnumData(WeightUnit, WeightUnit.KG),
        orderUnit: unitsData[1],
        vatType: vatData[1],
        priceExTax: 11.25,
        picture: {},
        rateDetails: [],
        promotionalRateDetails: [],
    },
    {
        id: 11,
        code: "ART11",
        commercialDesignation: "Article 11 commercial",
        label: "Article 11 label",
        brand: brandsData[0],
        superFamily: superItemFamilyData[2],
        family: itemFamilyData[1],
        geographicalOrigin: geographicalOriginData[0],
        packagings: [packagingTypeData[1], packagingTypeData[2]],
        degreeOfAlcohol: 7.02,
        density: 2,
        barcodeType: enumToEnumData(BarecodeType, BarecodeType.EAN13),
        barcode: "01234567890123",
        width: 12.2,
        height: 25.6,
        depth: 14,
        dimensionUnit: enumToEnumData(DimensionUnit, DimensionUnit.CM),
        byHowMuch: 12,
        packagePerLayer: 9,
        layerPerPallet: 3,
        netWeight: 1.5,
        grossWeight: 2,
        weightUnit: enumToEnumData(WeightUnit, WeightUnit.KG),
        orderUnit: unitsData[2],
        vatType: vatData[0],
        priceExTax: 38,
        picture: {},
        rateDetails: [],
        promotionalRateDetails: [],
    }, {
        id: 12,
        code: "ART12",
        commercialDesignation: "Article 12 commercial",
        label: "Article 12 label",
        brand: brandsData[0],
        superFamily: superItemFamilyData[0],
        family: itemFamilyData[1],
        geographicalOrigin: geographicalOriginData[1],
        packagings: [packagingTypeData[1], packagingTypeData[2]],
        degreeOfAlcohol: 3.21,
        density: 4,
        barcodeType: enumToEnumData(BarecodeType, BarecodeType.EAN13),
        barcode: "77224867591134",
        width: 11.5,
        height: 20.2,
        depth: 11,
        dimensionUnit: enumToEnumData(DimensionUnit, DimensionUnit.CM),
        byHowMuch: 20,
        packagePerLayer: 10,
        layerPerPallet: 6,
        netWeight: 3.5,
        grossWeight: 8,
        weightUnit: enumToEnumData(WeightUnit, WeightUnit.KG),
        orderUnit: unitsData[0],
        vatType: vatData[1],
        priceExTax: 100,
        picture: {},
        rateDetails: [],
        promotionalRateDetails: [],
    },
    {
        id: 13,
        code: "ART13",
        commercialDesignation: "Article 13 commercial",
        label: "Article 13 label",
        brand: brandsData[1],
        superFamily: superItemFamilyData[1],
        family: itemFamilyData[0],
        geographicalOrigin: geographicalOriginData[0],
        packagings: [packagingTypeData[1], packagingTypeData[2]],
        degreeOfAlcohol: 7.02,
        density: 2,
        barcodeType: enumToEnumData(BarecodeType, BarecodeType.EAN13),
        barcode: "01234567890123",
        width: 12.2,
        height: 25.6,
        depth: 14,
        dimensionUnit: enumToEnumData(DimensionUnit, DimensionUnit.CM),
        byHowMuch: 12,
        packagePerLayer: 9,
        layerPerPallet: 3,
        netWeight: 1.5,
        grossWeight: 2,
        weightUnit: enumToEnumData(WeightUnit, WeightUnit.KG),
        orderUnit: unitsData[2],
        vatType: vatData[0],
        priceExTax: 1500,
        picture: {},
        rateDetails: [],
        promotionalRateDetails: [],
    }, {
        id: 14,
        code: "ART14",
        commercialDesignation: "Article 14 commercial",
        label: "Article 14 label",
        brand: brandsData[0],
        superFamily: superItemFamilyData[2],
        family: itemFamilyData[2],
        geographicalOrigin: geographicalOriginData[1],
        packagings: [packagingTypeData[1], packagingTypeData[2]],
        degreeOfAlcohol: 3.21,
        density: 4,
        barcodeType: enumToEnumData(BarecodeType, BarecodeType.EAN13),
        barcode: "77224867591134",
        width: 11.5,
        height: 20.2,
        depth: 11,
        dimensionUnit: enumToEnumData(DimensionUnit, DimensionUnit.CM),
        byHowMuch: 20,
        packagePerLayer: 10,
        layerPerPallet: 6,
        netWeight: 3.5,
        grossWeight: 8,
        weightUnit: enumToEnumData(WeightUnit, WeightUnit.KG),
        orderUnit: unitsData[0],
        vatType: vatData[1],
        priceExTax: 620,
        picture: {},
        rateDetails: [],
        promotionalRateDetails: [],
    }]

export const ordersData: Order[] = [
    {
        id: 1,
        reference: "16740456",
        numOrder: (Math.random() * 999999999).toFixed(0),
        status: OrderStatus.BILLED,
        orderDate: new Date("01/20/2020"),
        deliveryDate: new Date("02/10/2020"),
        hasComments: true,
        deliveryAddress1: "",
        deliveryAddress2: "",
        deliveryAddress3: "",
        deliveryAddressZipCode1: customerDeliveryAddressesData["1"][0].zipCode1,
        deliveryAddressZipCode2: customerDeliveryAddressesData["1"][0].zipCode2,
        deliveryAddressCity: customerDeliveryAddressesData["1"][0].city,
        deliveryAddressCountry: customerDeliveryAddressesData["1"][0].country,
    },
    {
        id: 2,
        reference: "486877",
        numOrder: (Math.random() * 999999999).toFixed(0),
        status: OrderStatus.AWAITING_VALIDATION,
        orderDate: new Date("01/17/2021"),
        deliveryDate: new Date("02/18/2021"),
        hasComments: true,
        deliveryAddress1: "",
        deliveryAddress2: "",
        deliveryAddress3: "",
        deliveryAddressZipCode1: customerDeliveryAddressesData["1"][0].zipCode1,
        deliveryAddressZipCode2: customerDeliveryAddressesData["1"][0].zipCode2,
        deliveryAddressCity: customerDeliveryAddressesData["1"][0].city,
        deliveryAddressCountry: customerDeliveryAddressesData["1"][0].country,
    },
    {
        id: 3,
        reference: "581679",
        numOrder: (Math.random() * 999999999).toFixed(0),
        status: OrderStatus.NEW,
        orderDate: new Date("01/20/2020"),
        deliveryDate: new Date("02/10/2020"),
        hasComments: true,
        deliveryAddress1: "",
        deliveryAddress2: "",
        deliveryAddress3: "",
        deliveryAddressZipCode1: customerDeliveryAddressesData["1"][0].zipCode1,
        deliveryAddressZipCode2: customerDeliveryAddressesData["1"][0].zipCode2,
        deliveryAddressCity: customerDeliveryAddressesData["1"][0].city,
        deliveryAddressCountry: customerDeliveryAddressesData["1"][0].country,
    },
    {
        id: 4,
        reference: "851688",
        numOrder: (Math.random() * 999999999).toFixed(0),
        status: OrderStatus.DEALING_WITH,
        orderDate: new Date("01/20/2020"),
        deliveryDate: new Date("02/10/2020"),
        hasComments: false,
        deliveryAddress1: "",
        deliveryAddress2: "",
        deliveryAddress3: "",
        deliveryAddressZipCode1: customerDeliveryAddressesData["1"][0].zipCode1,
        deliveryAddressZipCode2: customerDeliveryAddressesData["1"][0].zipCode2,
        deliveryAddressCity: customerDeliveryAddressesData["1"][0].city,
        deliveryAddressCountry: customerDeliveryAddressesData["1"][0].country,
    },
    {
        id: 5,
        reference: "140856",
        numOrder: (Math.random() * 999999999).toFixed(0),
        status: OrderStatus.NEW,
        orderDate: new Date("01/20/2020"),
        deliveryDate: new Date("02/10/2020"),
        hasComments: false,
        deliveryAddress1: "",
        deliveryAddress2: "",
        deliveryAddress3: "",
        deliveryAddressZipCode1: customerDeliveryAddressesData["1"][0].zipCode1,
        deliveryAddressZipCode2: customerDeliveryAddressesData["1"][0].zipCode2,
        deliveryAddressCity: customerDeliveryAddressesData["1"][0].city,
        deliveryAddressCountry: customerDeliveryAddressesData["1"][0].country,
    },
    {
        id: 6,
        reference: "332854",
        numOrder: (Math.random() * 999999999).toFixed(0),
        status: OrderStatus.BILLED,
        orderDate: new Date("01/20/2020"),
        deliveryDate: new Date("02/10/2020"),
        hasComments: false,
        deliveryAddress1: "",
        deliveryAddress2: "",
        deliveryAddress3: "",
        deliveryAddressZipCode1: customerDeliveryAddressesData["1"][0].zipCode1,
        deliveryAddressZipCode2: customerDeliveryAddressesData["1"][0].zipCode2,
        deliveryAddressCity: customerDeliveryAddressesData["1"][0].city,
        deliveryAddressCountry: customerDeliveryAddressesData["1"][0].country,
    },
    {
        id: 7,
        reference: "339222",
        numOrder: (Math.random() * 999999999).toFixed(0),
        status: OrderStatus.SHIPPED,
        orderDate: new Date("01/20/2020"),
        deliveryDate: new Date("02/10/2020"),
        hasComments: false,
        deliveryAddress1: "",
        deliveryAddress2: "",
        deliveryAddress3: "",
        deliveryAddressZipCode1: customerDeliveryAddressesData["1"][0].zipCode1,
        deliveryAddressZipCode2: customerDeliveryAddressesData["1"][0].zipCode2,
        deliveryAddressCity: customerDeliveryAddressesData["1"][0].city,
        deliveryAddressCountry: customerDeliveryAddressesData["1"][0].country,
    },
    {
        id: 8,
        reference: "416046",
        numOrder: (Math.random() * 999999999).toFixed(0),
        status: OrderStatus.DEALING_WITH,
        orderDate: new Date("01/20/2020"),
        deliveryDate: new Date("02/10/2020"),
        hasComments: false,
        deliveryAddress1: "",
        deliveryAddress2: "",
        deliveryAddress3: "",
        deliveryAddressZipCode1: customerDeliveryAddressesData["1"][0].zipCode1,
        deliveryAddressZipCode2: customerDeliveryAddressesData["1"][0].zipCode2,
        deliveryAddressCity: customerDeliveryAddressesData["1"][0].city,
        deliveryAddressCountry: customerDeliveryAddressesData["1"][0].country,

    },
    {
        id: 9,
        reference: "579188",
        numOrder: (Math.random() * 999999999).toFixed(0),
        status: OrderStatus.NEW,
        orderDate: new Date("01/20/2020"),
        deliveryDate: new Date("02/10/2020"),
        hasComments: false,
        deliveryAddress1: "",
        deliveryAddress2: "",
        deliveryAddress3: "",
        deliveryAddressZipCode1: customerDeliveryAddressesData["1"][0].zipCode1,
        deliveryAddressZipCode2: customerDeliveryAddressesData["1"][0].zipCode2,
        deliveryAddressCity: customerDeliveryAddressesData["1"][0].city,
        deliveryAddressCountry: customerDeliveryAddressesData["1"][0].country,

    },
    {
        id: 10,
        reference: "244360",
        numOrder: (Math.random() * 999999999).toFixed(0),
        status: OrderStatus.SHIPPED,
        orderDate: new Date("01/20/2020"),
        deliveryDate: new Date("02/10/2020"),
        hasComments: false,
        deliveryAddress1: "",
        deliveryAddress2: "",
        deliveryAddress3: "",
        deliveryAddressZipCode1: customerDeliveryAddressesData["1"][0].zipCode1,
        deliveryAddressZipCode2: customerDeliveryAddressesData["1"][0].zipCode2,
        deliveryAddressCity: customerDeliveryAddressesData["1"][0].city,
        deliveryAddressCountry: customerDeliveryAddressesData["1"][0].country,

    },
    {
        id: 11,
        reference: "529109",
        numOrder: (Math.random() * 999999999).toFixed(0),
        status: OrderStatus.AWAITING_VALIDATION,
        orderDate: new Date("01/20/2020"),
        deliveryDate: new Date("02/10/2020"),
        hasComments: false,
        deliveryAddress1: "",
        deliveryAddress2: "",
        deliveryAddress3: "",
        deliveryAddressZipCode1: customerDeliveryAddressesData["1"][0].zipCode1,
        deliveryAddressZipCode2: customerDeliveryAddressesData["1"][0].zipCode2,
        deliveryAddressCity: customerDeliveryAddressesData["1"][0].city,
        deliveryAddressCountry: customerDeliveryAddressesData["1"][0].country,

    },
    {
        id: 12,
        reference: "542807",
        numOrder: (Math.random() * 999999999).toFixed(0),
        status: OrderStatus.AWAITING_VALIDATION,
        orderDate: new Date("01/20/2020"),
        deliveryDate: new Date("02/10/2020"),
        hasComments: false,
        deliveryAddress1: "",
        deliveryAddress2: "",
        deliveryAddress3: "",
        deliveryAddressZipCode1: customerDeliveryAddressesData["1"][0].zipCode1,
        deliveryAddressZipCode2: customerDeliveryAddressesData["1"][0].zipCode2,
        deliveryAddressCity: customerDeliveryAddressesData["1"][0].city,
        deliveryAddressCountry: customerDeliveryAddressesData["1"][0].country,

    },
    {
        id: 13,
        reference: "117169",
        numOrder: (Math.random() * 999999999).toFixed(0),
        status: OrderStatus.BILLED,
        orderDate: new Date("01/20/2020"),
        deliveryDate: new Date("02/10/2020"),
        hasComments: false,
        deliveryAddress1: "",
        deliveryAddress2: "",
        deliveryAddress3: "",
        deliveryAddressZipCode1: customerDeliveryAddressesData["1"][0].zipCode1,
        deliveryAddressZipCode2: customerDeliveryAddressesData["1"][0].zipCode2,
        deliveryAddressCity: customerDeliveryAddressesData["1"][0].city,
        deliveryAddressCountry: customerDeliveryAddressesData["1"][0].country,

    },
    {
        id: 14,
        reference: "289419",
        numOrder: (Math.random() * 999999999).toFixed(0),
        orderDate: new Date("01/20/2020"),
        deliveryDate: new Date("02/10/2020"),
        hasComments: false,
        deliveryAddress1: "",
        deliveryAddress2: "",
        deliveryAddress3: "",
        deliveryAddressZipCode1: customerDeliveryAddressesData["1"][0].zipCode1,
        deliveryAddressZipCode2: customerDeliveryAddressesData["1"][0].zipCode2,
        deliveryAddressCity: customerDeliveryAddressesData["1"][0].city,
        deliveryAddressCountry: customerDeliveryAddressesData["1"][0].country,

    }
]
export const orderLinesData: { [orderId: number]: OrderLine[] } =
    {
        "1": [
            {
                id: 1,
                item: itemsData[0],
                itemCode: itemsData[0].code,
                itemLabel: itemsData[0].label,
                quantity: 10,
                grossPrice: itemsData[0].priceExTax,
                itemTotalExTax: itemsData[0].priceExTax ? itemsData[0].priceExTax * 10 : 0
            }, {
                id: 2,
                item: itemsData[12],
                itemCode: itemsData[12].code,
                itemLabel: itemsData[12].label,
                quantity: 5,
                grossPrice: itemsData[12].priceExTax,
                itemTotalExTax: itemsData[12].priceExTax ? itemsData[12].priceExTax * 5 : 0
            }, {
                id: 3,
                item: itemsData[3],
                itemCode: itemsData[3].code,
                itemLabel: itemsData[3].label,
                quantity: 15,
                grossPrice: itemsData[3].priceExTax,
                itemTotalExTax: itemsData[3].priceExTax ? itemsData[3].priceExTax * 15 : 0
            }
        ],
        "2": [
            {
                id: 21,
                item: itemsData[10],
                itemCode: itemsData[10].code,
                itemLabel: itemsData[10].label,
                quantity: 100,
                grossPrice: itemsData[10].priceExTax,
                itemTotalExTax: itemsData[10].priceExTax ? itemsData[10].priceExTax * 100 : 0
            }, {
                id: 22,
                item: itemsData[8],
                itemCode: itemsData[8].code,
                itemLabel: itemsData[8].label,
                quantity: 50,
                grossPrice: itemsData[8].priceExTax,
                itemTotalExTax: itemsData[8].priceExTax ? itemsData[8].priceExTax * 50 : 0
            }, {
                id: 23,
                item: itemsData[11],
                itemCode: itemsData[11].code,
                itemLabel: itemsData[11].label,
                quantity: 3,
                grossPrice: itemsData[11].priceExTax,
                itemTotalExTax: itemsData[11].priceExTax ? itemsData[11].priceExTax * 3 : 0
            }
        ],
        "3": [
            {
                id: 31,
                item: itemsData[5],
                itemCode: itemsData[5].code,
                itemLabel: itemsData[5].label,
                quantity: 10,
                grossPrice: itemsData[5].priceExTax,
                itemTotalExTax: ((itemsData[5].priceExTax || 0) * 10) - (((itemsData[5].priceExTax || 0) * 10) * (50 / 100)),
            }, {
                id: 32,
                item: itemsData[12],
                itemCode: itemsData[12].code,
                itemLabel: itemsData[12].label,
                quantity: 5,
                grossPrice: itemsData[12].priceExTax,
                itemTotalExTax: ((itemsData[12].priceExTax || 0) * 5) - (((itemsData[12].priceExTax || 0) * 10) * (50 / 100)),
            }, {
                id: 33,
                item: itemsData[13],
                itemCode: itemsData[13].code,
                itemLabel: itemsData[13].label,
                quantity: 15,
                grossPrice: itemsData[13].priceExTax,
                itemTotalExTax: ((itemsData[13].priceExTax || 0) * 15) - (((itemsData[13].priceExTax || 0) * 10) * (50 / 100)),
            }],
        "4": [
            {
                id: 41,
                item: itemsData[6],
                itemCode: itemsData[6].code,
                itemLabel: itemsData[6].label,
                quantity: 25,
                grossPrice: itemsData[6].priceExTax,
                itemTotalExTax: itemsData[6].priceExTax ? itemsData[6].priceExTax * 25 : 0
            }, {
                id: 42,
                item: itemsData[7],
                itemCode: itemsData[7].code,
                itemLabel: itemsData[7].label,
                quantity: 5,
                grossPrice: itemsData[7].priceExTax,
                itemTotalExTax: itemsData[7].priceExTax ? itemsData[7].priceExTax * 5 : 0
            }, {
                id: 43,
                item: itemsData[8],
                itemCode: itemsData[8].code,
                itemLabel: itemsData[8].label,
                quantity: 13,
                grossPrice: itemsData[8].priceExTax,
                itemTotalExTax: itemsData[8].priceExTax ? itemsData[8].priceExTax * 13 : 0
            }],
        "5": [
            {
                id: 51,
                item: itemsData[0],
                itemCode: itemsData[0].code,
                itemLabel: itemsData[0].label,
                quantity: 7,
                grossPrice: itemsData[0].priceExTax,
                itemTotalExTax: itemsData[0].priceExTax ? itemsData[0].priceExTax * 7 : 0
            }, {
                id: 52,
                item: itemsData[12],
                itemCode: itemsData[12].code,
                itemLabel: itemsData[12].label,
                quantity: 2,
                grossPrice: itemsData[12].priceExTax,
                itemTotalExTax: itemsData[12].priceExTax ? itemsData[12].priceExTax * 2 : 0
            }, {
                id: 53,
                item: itemsData[3],
                itemCode: itemsData[3].code,
                itemLabel: itemsData[3].label,
                quantity: 6,
                grossPrice: itemsData[3].priceExTax,
                itemTotalExTax: itemsData[3].priceExTax ? itemsData[3].priceExTax * 6 : 0
            }],
        "6": [
            {
                id: 61,
                item: itemsData[3],
                itemCode: itemsData[3].code,
                itemLabel: itemsData[3].label,
                quantity: 10,
                grossPrice: itemsData[3].priceExTax,
                itemTotalExTax: itemsData[3].priceExTax ? itemsData[3].priceExTax * 10 : 0
            }, {
                id: 62,
                item: itemsData[12],
                itemCode: itemsData[12].code,
                itemLabel: itemsData[12].label,
                quantity: 5,
                grossPrice: itemsData[12].priceExTax,
                itemTotalExTax: itemsData[12].priceExTax ? itemsData[12].priceExTax * 5 : 0
            }, {
                id: 63,
                item: itemsData[7],
                itemCode: itemsData[7].code,
                itemLabel: itemsData[7].label,
                quantity: 12,
                grossPrice: itemsData[7].priceExTax,
                itemTotalExTax: itemsData[7].priceExTax ? itemsData[7].priceExTax * 12 : 0
            }],
        "7": [
            {
                id: 71,
                item: itemsData[5],
                itemCode: itemsData[5].code,
                itemLabel: itemsData[5].label,
                quantity: 32,
                grossPrice: itemsData[5].priceExTax,
                itemTotalExTax: itemsData[5].priceExTax ? itemsData[5].priceExTax * 32 : 0
            }, {
                id: 72,
                item: itemsData[10],
                itemCode: itemsData[10].code,
                itemLabel: itemsData[10].label,
                quantity: 9,
                grossPrice: itemsData[10].priceExTax,
                itemTotalExTax: itemsData[10].priceExTax ? itemsData[10].priceExTax * 9 : 0
            }, {
                id: 73,
                item: itemsData[11],
                itemCode: itemsData[11].code,
                itemLabel: itemsData[11].label,
                quantity: 15,
                grossPrice: itemsData[11].priceExTax,
                itemTotalExTax: itemsData[11].priceExTax ? itemsData[11].priceExTax * 15 : 0
            }],
        "8": [
            {
                id: 81,
                item: itemsData[1],
                itemCode: itemsData[1].code,
                itemLabel: itemsData[1].label,
                quantity: 10,
                grossPrice: itemsData[1].priceExTax,
                itemTotalExTax: itemsData[1].priceExTax ? itemsData[1].priceExTax * 10 : 0
            }, {
                id: 82,
                item: itemsData[9],
                itemCode: itemsData[9].code,
                itemLabel: itemsData[9].label,
                quantity: 5,
                grossPrice: itemsData[9].priceExTax,
                itemTotalExTax: itemsData[9].priceExTax ? itemsData[9].priceExTax * 5 : 0
            }, {
                id: 83,
                item: itemsData[3],
                itemCode: itemsData[3].code,
                itemLabel: itemsData[3].label,
                quantity: 12,
                grossPrice: itemsData[3].priceExTax,
                itemTotalExTax: itemsData[3].priceExTax ? itemsData[3].priceExTax * 12 : 0
            }],
        "9": [
            {
                id: 91,
                item: itemsData[8],
                itemCode: itemsData[8].code,
                itemLabel: itemsData[8].label,
                quantity: 3,
                grossPrice: itemsData[8].priceExTax,
                itemTotalExTax: itemsData[8].priceExTax ? itemsData[8].priceExTax * 3 : 0
            }, {
                id: 92,
                item: itemsData[10],
                itemCode: itemsData[10].code,
                itemLabel: itemsData[10].label,
                quantity: 4,
                grossPrice: itemsData[10].priceExTax,
                itemTotalExTax: itemsData[10].priceExTax ? itemsData[10].priceExTax * 4 : 0
            }, {
                id: 93,
                item: itemsData[5],
                itemCode: itemsData[5].code,
                itemLabel: itemsData[5].label,
                quantity: 2,
                grossPrice: itemsData[5].priceExTax,
                itemTotalExTax: itemsData[5].priceExTax ? itemsData[5].priceExTax * 2 : 0
            }],
        "10": [
            {
                id: 101,
                item: itemsData[2],
                itemCode: itemsData[2].code,
                itemLabel: itemsData[2].label,
                quantity: 10,
                grossPrice: itemsData[2].priceExTax,
                itemTotalExTax: itemsData[2].priceExTax ? itemsData[2].priceExTax * 10 : 0
            }, {
                id: 102,
                item: itemsData[12],
                itemCode: itemsData[12].code,
                itemLabel: itemsData[12].label,
                quantity: 5,
                grossPrice: itemsData[12].priceExTax,
                itemTotalExTax: 1500 * 5
            }, {
                id: 103,
                item: itemsData[6],
                itemCode: itemsData[6].code,
                itemLabel: itemsData[6].label,
                quantity: 1,
                grossPrice: itemsData[6].priceExTax,
                itemTotalExTax: itemsData[6].priceExTax ? itemsData[6].priceExTax * 1 : 0
            }],
        "11": [
            {
                id: 111,
                item: itemsData[0],
                itemCode: itemsData[0].code,
                itemLabel: itemsData[0].label,
                quantity: 10,
                grossPrice: itemsData[0].priceExTax,
                itemTotalExTax: itemsData[0].priceExTax ? itemsData[0].priceExTax * 10 : 0
            }, {
                id: 112,
                item: itemsData[12],
                itemCode: itemsData[12].code,
                itemLabel: itemsData[12].label,
                quantity: 50,
                grossPrice: itemsData[12].priceExTax,
                itemTotalExTax: itemsData[12].priceExTax ? itemsData[12].priceExTax * 50 : 0
            }, {
                id: 113,
                item: itemsData[4],
                itemCode: itemsData[4].code,
                itemLabel: itemsData[4].label,
                quantity: 15,
                grossPrice: itemsData[4].priceExTax,
                itemTotalExTax: itemsData[4].priceExTax ? itemsData[4].priceExTax * 15 : 0
            }],
        "12": [
            {
                id: 121,
                item: itemsData[1],
                itemCode: itemsData[1].code,
                itemLabel: itemsData[1].label,
                quantity: 10,
                grossPrice: itemsData[1].priceExTax,
                itemTotalExTax: itemsData[1].priceExTax ? itemsData[1].priceExTax * 10 : 0
            }, {
                id: 122,
                item: itemsData[8],
                itemCode: itemsData[8].code,
                itemLabel: itemsData[8].label,
                quantity: 5,
                grossPrice: itemsData[8].priceExTax,
                itemTotalExTax: itemsData[8].priceExTax ? itemsData[8].priceExTax * 5 : 0
            }, {
                id: 123,
                item: itemsData[7],
                itemCode: itemsData[7].code,
                itemLabel: itemsData[7].label,
                quantity: 1,
                grossPrice: itemsData[3].priceExTax,
                itemTotalExTax: itemsData[7].priceExTax ? itemsData[7].priceExTax * 1 : 0
            }],
        "13": [
            {
                id: 131,
                item: itemsData[0],
                itemCode: itemsData[0].code,
                itemLabel: itemsData[0].label,
                quantity: 10,
                grossPrice: itemsData[0].priceExTax,
            }, {
                id: 132,
                item: itemsData[12],
                itemCode: itemsData[12].code,
                itemLabel: itemsData[12].label,
                quantity: 5,
                grossPrice: itemsData[12].priceExTax,
            }, {
                id: 133,
                item: itemsData[3],
                itemCode: itemsData[3].code,
                itemLabel: itemsData[3].label,
                quantity: 15,
                grossPrice: itemsData[3].priceExTax,
            }],
        "14": [
            {
                id: 141,
                item: itemsData[0],
                itemCode: itemsData[0].code,
                itemLabel: itemsData[0].label,
                quantity: 10,
                grossPrice: itemsData[0].priceExTax,
            }, {
                id: 142,
                item: itemsData[12],
                itemCode: itemsData[12].code,
                itemLabel: itemsData[12].label,
                quantity: 5,
                grossPrice: itemsData[12].priceExTax,
            }, {
                id: 143,
                item: itemsData[3],
                itemCode: itemsData[3].code,
                itemLabel: itemsData[3].label,
                quantity: 15,
                grossPrice: itemsData[3].priceExTax,
            }],
    }

export const userItemFavoritesData = {
    [usersData[0].id]: [itemsData[0].id, itemsData[3].id, itemsData[7].id, itemsData[8].id, itemsData[9].id, itemsData[11].id]
}

export const orderCommentData: OrderComment[] = [
    {
        id: 1,
        profile: "User 1",
        createdAt: new Date(2021, 12, 14),
        message: "commentaire 1 commentaire 1 commentaire 1 commentaire 1 commentaire 1 commentaire 1 commentaire 1 commentaire 1 commentaire 1 commentaire 1 commentaire 1 commentaire 1 commentaire 1 commentaire 1 commentaire 1 commentaire 1 commentaire 1 commentaire 1"
    },
    {
        id: 2,
        profile: "User 2",
        createdAt: new Date(2021, 12, 10),
        message: "commentaire 2 commentaire 2 commentaire 2 commentaire 2 commentaire 2 commentaire 2 commentaire 2 commentaire 2 commentaire 2 commentaire 2 commentaire 2 commentaire 2 commentaire 2 commentaire 2 commentaire 2 commentaire 2 commentaire 2 commentaire 2"
    },
    {
        id: 3,
        profile: "User 3",
        createdAt: new Date(2021, 12, 6),
        message: "commentaire 3 commentaire 3 commentaire 3 commentaire 3 commentaire 3 commentaire 3 commentaire 3 commentaire 3 commentaire 3 commentaire 3 commentaire 3 commentaire 3commentaire 3 commentaire 3 commentaire 3 commentaire 3 commentaire 3 commentaire 3 "
    }
]