import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    BadgeProps,
    Button,
    Checkbox,
    FormGroup,
    Grid,
    Paper,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip
} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import {DividingHR} from "../../../Utils/DividingHR";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import PublicIcon from "@mui/icons-material/Public";
import SellIcon from '@mui/icons-material/Sell';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import BackupIcon from '@mui/icons-material/Backup';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InventoryIcon from '@mui/icons-material/Inventory';
import {ItemAllFormData, ItemFilter} from "../../../../../../corelogic/models/item";
import {UserRoleCode} from "../../../../../../corelogic/models/user";
import {useSelector} from "react-redux";
import {getUserLoggedSelector} from "../../../../../../store/selectors/sessionSelector";
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Badge from '@mui/material/Badge';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import YardIcon from "@mui/icons-material/Yard";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import {ItemFamily} from "../../../../../../corelogic/models/parameter";
import {TreeItem, TreeView} from "@mui/x-tree-view";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const StyledBadge = styled(Badge)<BadgeProps>(({theme}) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))

interface ItemFilterAccordionProps {
    dataFormFields: ItemAllFormData
    onClickResetAllFilters?: () => void
    onClickApplyFilters?: (itemFilterState: ItemFilter, filterCount: number) => void
    displayWithFixedHeight?: boolean
    disableIsBlockedIsSold?: boolean
    isCreationMode?: boolean,
    withReferenced?: boolean
    withOrderSchedule?: boolean
}

const ItemFilterAccordion = (props: ItemFilterAccordionProps) => {
    const {
        dataFormFields,
        onClickApplyFilters,
        isCreationMode,
        displayWithFixedHeight,
        disableIsBlockedIsSold,
        withReferenced,
        withOrderSchedule
    } = props
    const {userLogged} = useSelector(getUserLoggedSelector)
    const [isVdc, setIsVdc] = useState(document.location.hostname === process.env.REACT_APP_CTX_CLI_VDC && userLogged?.role?.code === UserRoleCode.CUSTOMER ? true : undefined)
    const getDefaultFilter = () => {
        return {
            search: "",
            familyCodes: [],
            superFamilyCodes: [],
            brandCodes: [],
            geographicalOriginCodes: [],
            authorizationToSellOnly: userLogged?.role?.code === UserRoleCode.COMMERCIAL || userLogged?.role?.code === UserRoleCode.CUSTOMER,
            favorite: isVdc,
            isManagedInStock: undefined,
            isSold: true,
            isPurchased: undefined,
            isOrganic: undefined,
            isBlocked: false,
            isReferenced: withReferenced,
            isPublishedInTheCatalog: true,
            isExportECommerce: undefined,
            isOrderSchedule: undefined,
            familyIds: [],
            superFamilyIds: [],
            brandIds: [],
            geographicalOriginIds: [],
        }
    }
    const intl = useIntl()
    const [filterCount, setFilterCount] = useState(0)
    const [itemFilter, setItemFilter] = useState<ItemFilter>(getDefaultFilter())
    const [filterAccordionExpand, setFilterAccordionExpand] = useState({
        family: itemFilter.familyIds.length > 0,
        superFamily: itemFilter.superFamilyIds.length > 0,
        brand: itemFilter.brandIds.length > 0,
        geographicalOrigin: itemFilter.geographicalOriginIds.length > 0
    })

    const groupedFamilies = useMemo(() => {
        const grouped = dataFormFields.families.reduce((acc, family: ItemFamily) => {
            const superFamilyId = family.itemSuperFamily?.id || 0
            const superFamilyLabel = family.itemSuperFamily?.label || "Autres"
            if (!acc[superFamilyId]) {
                acc[superFamilyId] = {label: superFamilyLabel, families: []}
            }
            acc[superFamilyId].families.push(family)
            return acc
        }, {} as Record<number, { label: string, families: ItemFamily[] }>)

        // Trier les familles à l'intérieur de chaque superfamille
        Object.values(grouped).forEach(group => {
            group.families.sort((a, b) => (a.label || "").localeCompare(b.label || ""))
        })

        // Trier les superfamilles par label
        const sortedGrouped = Object.entries(grouped)
            .sort(([, a], [, b]) => (a.label || "").localeCompare(b.label || ""))

        return sortedGrouped
    }, [dataFormFields.families])

    const mapFilterObjectToGetFilterCount = useCallback((filters: ItemFilter, keysToIgnore: string[] = []) => {
        let count = 0
        Object.entries(filters).forEach(([key, value]) => {
            if (keysToIgnore.includes(key)) {
                return
            }
            if (value === true || value === false) {
                count++
            } else if (Array.isArray(value)) {
                count += Array.from(value).length
            }
        })
        return count
    }, [])

    const getCountFilterWithIgnoredKeys = (itemFilter: ItemFilter) => {
        if (disableIsBlockedIsSold) {
            return mapFilterObjectToGetFilterCount(itemFilter, ["authorizationToSellOnly", "isSold", "isBlocked"])
        } else {
            return mapFilterObjectToGetFilterCount(itemFilter, ["authorizationToSellOnly"])
        }
    }

    useEffect(() => {
        setIsVdc(document.location.hostname === process.env.REACT_APP_CTX_CLI_VDC && userLogged?.role?.code === UserRoleCode.CUSTOMER ? true : undefined)
    }, [userLogged])

    useEffect(() => {
        if (disableIsBlockedIsSold) {
            setFilterCount(mapFilterObjectToGetFilterCount(itemFilter, ["authorizationToSellOnly", "isSold", "isBlocked"]))
        } else {
            setFilterCount(mapFilterObjectToGetFilterCount(itemFilter, ["authorizationToSellOnly"]))
        }
    }, [disableIsBlockedIsSold, itemFilter, mapFilterObjectToGetFilterCount])

    const applyAllFilters = () => {
        if (onClickApplyFilters) {
            onClickApplyFilters(itemFilter, filterCount)
        }
    }

    const resetAllFilters = () => {
        setItemFilter(getDefaultFilter())
        setFilterAccordionExpand({
            family: false,
            superFamily: false,
            brand: false,
            geographicalOrigin: false
        })
        const count = getCountFilterWithIgnoredKeys(getDefaultFilter())
        setFilterCount(count)
        if (onClickApplyFilters) {
            onClickApplyFilters(getDefaultFilter(), count)
        }
    }

    const handleToggleAccordionState = (name: string) => {
        setFilterAccordionExpand({
            ...filterAccordionExpand,
            [name]: !filterAccordionExpand[name as keyof Object]
        })
    }

    const handleFilterBy = (e: React.ChangeEvent<HTMLInputElement>, filterName: string) => {
        if (!filterName) {
            return
        }
        const code = Number(e.target.name)
        const filteValues = itemFilter[filterName as keyof ItemFilter] as number[] || []

        if (e.target.checked) {
            if (!filteValues.includes(code)) {
                setItemFilter({
                    ...itemFilter,
                    [filterName]: [...filteValues, code]
                })
            }
        } else {
            setItemFilter({
                ...itemFilter,
                [filterName]: filteValues.filter(c => c !== code)
            })
        }
    }

    const handleFilterByBoolean = (event: React.MouseEvent<HTMLElement>, newState: boolean | null, filterName: string) => {
        if (newState === true) {
            setItemFilter({...itemFilter, [filterName]: true})
        } else if (newState === false) {
            setItemFilter({...itemFilter, [filterName]: false})
        } else {
            setItemFilter({...itemFilter, [filterName]: undefined})
        }
    }

    return (
        <>
            <Box sx={{maxHeight: displayWithFixedHeight ? 700 : 1000, overflowY: "auto", pb: 1, px: 1}}>
                <Grid container direction="column" spacing={1} width={300}>
                    <Grid item>
                        <Grid container justifyContent="space-evenly">
                            <Grid item>
                                <StyledBadge badgeContent={filterCount} color="error">
                                    <Tooltip title={intl.formatMessage(getMessageDescriptor("genericApplyFilters"))}>
                                        <Button variant="contained" onClick={applyAllFilters}>
                                            <FilterAltIcon/>
                                        </Button>
                                    </Tooltip>
                                </StyledBadge>
                            </Grid>
                            <Grid item>
                                <Tooltip title={intl.formatMessage(getMessageDescriptor("itemListFilterResetTitle"))}>
                                    <Button variant="contained" onClick={resetAllFilters}>
                                        <FilterAltOffIcon/>
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                    <DividingHR subContent style={{marginBottom: 0}}/>
                    {withReferenced && <Grid item>
                        <Paper elevation={5}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item px={2}>
                                    <Grid container alignItems="center">
                                        <FavoriteIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                        <Typography fontWeight="bolder" paddingLeft={2}>
                                            <FormattedMessage id={messages.itemListFilterFieldReferencedTitle.id}/>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <ToggleButtonGroup
                                        value={itemFilter.isReferenced}
                                        exclusive
                                        onChange={(e, value) => handleFilterByBoolean(e, value, "isReferenced")}
                                        aria-label="is Referenced state"
                                    >
                                        <ToggleButton value={true} aria-label="left aligned" color="success"
                                                      size="small">
                                            <CheckCircleIcon/>
                                        </ToggleButton>
                                        <ToggleButton value={false} aria-label="centered" color="error" size="small">
                                            <UnpublishedIcon/>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>}
                    {withOrderSchedule && <Grid item>
                        <Paper elevation={5}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item px={2}>
                                    <Grid container alignItems="center">
                                        <WatchLaterIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                        <Typography fontWeight="bolder" paddingLeft={2}>
                                            {/*<FormattedMessage id={messages.itemListFilterFieldReferencedTitle.id}/>*/}
                                            Cadencier
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <ToggleButtonGroup
                                        value={itemFilter.isOrderSchedule}
                                        exclusive
                                        onChange={(e, value) => handleFilterByBoolean(e, value, "isOrderSchedule")}
                                        aria-label="is Order Schedule state"
                                    >
                                        <ToggleButton value={true} aria-label="left aligned" color="success"
                                                      size="small">
                                            <CheckCircleIcon/>
                                        </ToggleButton>
                                        <ToggleButton value={false} aria-label="centered" color="error" size="small">
                                            <UnpublishedIcon/>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>}
                    <Grid item>
                        <Paper elevation={5}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item px={2}>
                                    <Grid container alignItems="center">
                                        <FavoriteIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                        <Typography fontWeight="bolder" paddingLeft={2}>
                                            <FormattedMessage id={messages.itemListFilterFieldFavouriteTitle.id}/>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <ToggleButtonGroup
                                        value={itemFilter.favorite}
                                        exclusive
                                        onChange={(e, value) => handleFilterByBoolean(e, value, "favorite")}
                                        aria-label="is favorite state"
                                    >
                                        <ToggleButton value={true} aria-label="left aligned" color="success"
                                                      size="small">
                                            <CheckCircleIcon/>
                                        </ToggleButton>
                                        <ToggleButton value={false} aria-label="centered" color="error" size="small">
                                            <UnpublishedIcon/>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    {userLogged?.role?.code !== UserRoleCode.CUSTOMER && !disableIsBlockedIsSold
                        &&
                        <>
                            <Grid item>
                                <Paper elevation={5}>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item px={2}>
                                            <Grid container alignItems="center">
                                                <SellIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                                <Typography fontWeight="bolder" paddingLeft={2}>
                                                    <FormattedMessage id={messages.itemListCardFieldSold.id}/>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <ToggleButtonGroup
                                                value={itemFilter.isSold}
                                                exclusive
                                                onChange={(e, value) => handleFilterByBoolean(e, value, "isSold")}
                                                aria-label="is sold state"
                                            >
                                                <ToggleButton value={true} aria-label="left aligned" color="success"
                                                              size="small">
                                                    <CheckCircleIcon/>
                                                </ToggleButton>
                                                <ToggleButton value={false} aria-label="centered" color="error"
                                                              size="small">
                                                    <UnpublishedIcon/>
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Paper elevation={5}>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item px={2}>
                                            <Grid container alignItems="center">
                                                <RemoveCircleIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                                <Typography fontWeight="bolder" paddingLeft={2}>
                                                    <FormattedMessage id={messages.itemListCardFieldBlocked.id}/>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <ToggleButtonGroup
                                                value={itemFilter.isBlocked}
                                                exclusive
                                                onChange={(e, value) => handleFilterByBoolean(e, value, "isBlocked")}
                                                aria-label="is blocked state"
                                            >
                                                <ToggleButton value={true} aria-label="left aligned" color="success"
                                                              size="small">
                                                    <CheckCircleIcon/>
                                                </ToggleButton>
                                                <ToggleButton value={false} aria-label="centered" color="error"
                                                              size="small">
                                                    <UnpublishedIcon/>
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </>
                    }
                    <Grid item>
                        <Paper elevation={5}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item md={8} px={2}>
                                    <Grid container alignItems="center">
                                        <Grid item md={2}>
                                            <NewspaperIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                        </Grid>
                                        <Grid item md={10} pl={0}>
                                            <Tooltip
                                                title={intl.formatMessage(getMessageDescriptor("itemListCardFieldPublishedInCatalog"))}>
                                                <Typography fontWeight="bolder" paddingLeft={2}
                                                            textOverflow={"ellipsis"} noWrap>
                                                    <FormattedMessage
                                                        id={messages.itemListCardFieldPublishedInCatalog.id}/>
                                                </Typography>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={4} p={0} textAlign={"end"}>
                                    <ToggleButtonGroup
                                        value={itemFilter.isPublishedInTheCatalog}
                                        exclusive
                                        onChange={(e, value) => handleFilterByBoolean(e, value, "isPublishedInTheCatalog")}
                                        aria-label="is publishedInTheCatalog state"
                                    >
                                        <ToggleButton value={true} aria-label="left aligned" color="success"
                                                      size="small">
                                            <CheckCircleIcon/>
                                        </ToggleButton>
                                        <ToggleButton value={false} aria-label="centered" color="error"
                                                      size="small">
                                            <UnpublishedIcon/>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item>
                        <Accordion elevation={5} expanded={filterAccordionExpand.family}
                                   onChange={() => handleToggleAccordionState("family")}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>}>
                                <Grid container>
                                    <WorkspacesIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                    <Typography fontWeight="bolder" paddingLeft={2}>
                                        <FormattedMessage id={messages.itemListFilterFieldSuperFamilyTitle.id}/>
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <DividingHR subContent style={{margin: 0}}/>
                            <AccordionDetails sx={{maxHeight: "300px", overflow: "auto"}}>
                                <TreeView
                                    defaultCollapseIcon={<ExpandMoreIcon sx={{marginRight: 2}}/>}
                                    defaultExpandIcon={<ChevronRightIcon sx={{marginRight: 2}}/>}
                                >
                                    {groupedFamilies.map(([superFamilyId, group]) => (
                                        <TreeItem
                                            nodeId={superFamilyId}
                                            label={
                                                <Tooltip title={group.label}>
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            onClick={(event) => event.stopPropagation()}
                                                            onChange={(e) => handleFilterBy(e, "superFamilyIds")}
                                                            checked={itemFilter.superFamilyIds?.includes(Number(superFamilyId))}
                                                            name={String(superFamilyId)}
                                                            sx={{
                                                                color: "rgba(255, 82, 82, 1)",
                                                                padding: 0,
                                                                marginRight: 1,
                                                                marginLeft: 1
                                                            }}/>}
                                                        label={
                                                            <Typography sx={{
                                                                width: 160,
                                                                overflow: "hidden",
                                                                whiteSpace: "nowrap",
                                                                textOverflow: "ellipsis"
                                                            }}
                                                                        onClick={(event) => event.stopPropagation()}>
                                                                {group.label}
                                                            </Typography>
                                                        }
                                                        onClick={(event) => event.stopPropagation()}
                                                    />
                                                </Tooltip>
                                            }
                                            key={superFamilyId}
                                        >
                                            {group.families.map(family => (
                                                <TreeItem
                                                    nodeId={`${superFamilyId}-${family.id}`}
                                                    label={
                                                        <Tooltip title={family.label}>
                                                            <FormControlLabel
                                                                control={<Checkbox
                                                                    onClick={(event) => event.stopPropagation()}
                                                                    onChange={(e) => handleFilterBy(e, "familyIds")}
                                                                    checked={itemFilter.familyIds?.includes(family.id)}
                                                                    name={String(family.id)}
                                                                    sx={{
                                                                        color: "rgba(255, 82, 82, 1)",
                                                                        padding: 0
                                                                    }}/>}
                                                                label={
                                                                    <Typography sx={{
                                                                        width: 156,
                                                                        overflow: "hidden",
                                                                        whiteSpace: "nowrap",
                                                                        textOverflow: "ellipsis"
                                                                    }}
                                                                                onClick={(event) => event.stopPropagation()}>
                                                                        {family.label}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </Tooltip>
                                                    }
                                                    key={`${superFamilyId}-${family.id}`}
                                                />
                                            ))}
                                        </TreeItem>
                                    ))}
                                </TreeView>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    {dataFormFields.brands.length > 0 && <Grid item>
                        <Accordion elevation={5} expanded={filterAccordionExpand.brand}
                                   onChange={() => handleToggleAccordionState("brand")}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>}>
                                <Grid container>
                                    <BrandingWatermarkIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                    <Typography fontWeight="bolder" paddingLeft={2}>
                                        <FormattedMessage id={messages.itemListFilterFieldBrandTitle.id}/>
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <DividingHR subContent style={{margin: 0}}/>
                            <AccordionDetails sx={{maxHeight: "300px", overflow: "auto"}}>
                                <FormGroup>
                                    {dataFormFields.brands.map(b => {
                                        return (
                                            <Tooltip title={b.label ? b.label : ""} key={b.id}>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        onChange={(e) => handleFilterBy(e, "brandIds")}
                                                        checked={itemFilter.brandIds?.includes(b.id)}
                                                        name={String(b.id)}
                                                        sx={{color: "rgba(255, 82, 82, 1)", padding: 0}}/>}
                                                    label={
                                                        <Typography sx={{
                                                            width: 160,
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis"
                                                        }}>
                                                            {b.label ? b.label : ""}
                                                        </Typography>
                                                    }
                                                />
                                            </Tooltip>
                                        )
                                    })}
                                </FormGroup>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>}
                    <Grid item>
                        <Paper elevation={5}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item md={8} px={2}>
                                    <Grid container alignItems="center">
                                        <Grid item md={2}>
                                            <BackupIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                        </Grid>
                                        <Grid item md={10} pl={0}>
                                            <Tooltip
                                                title={intl.formatMessage(getMessageDescriptor("itemListCardFieldExportECommerce"))}>
                                                <Typography fontWeight="bolder" paddingLeft={2}
                                                            textOverflow={"ellipsis"} noWrap>
                                                    <FormattedMessage
                                                        id={messages.itemListCardFieldExportECommerce.id}/>
                                                </Typography>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={4} p={0} textAlign={"end"}>
                                    <ToggleButtonGroup
                                        value={itemFilter.isExportECommerce}
                                        exclusive
                                        onChange={(e, value) => handleFilterByBoolean(e, value, "isExportECommerce")}
                                        aria-label="is exportECommerce state"
                                    >
                                        <ToggleButton value={true} aria-label="left aligned" color="success"
                                                      size="small">
                                            <CheckCircleIcon/>
                                        </ToggleButton>
                                        <ToggleButton value={false} aria-label="centered" color="error"
                                                      size="small">
                                            <UnpublishedIcon/>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item>
                        <Paper elevation={5}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item px={2}>
                                    <Grid container alignItems="center">
                                        <YardIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                        <Typography fontWeight="bolder" paddingLeft={2}>
                                            <FormattedMessage id={messages.itemListCardFieldisBio.id}/>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <ToggleButtonGroup
                                        value={itemFilter.isOrganic}
                                        exclusive
                                        onChange={(e, value) => handleFilterByBoolean(e, value, "isOrganic")}
                                        aria-label="is organic state"
                                    >
                                        <ToggleButton value={true} aria-label="left aligned" color="success"
                                                      size="small">
                                            <CheckCircleIcon/>
                                        </ToggleButton>
                                        <ToggleButton value={false} aria-label="centered" color="error" size="small">
                                            <UnpublishedIcon/>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    {
                        (userLogged?.role?.code !== UserRoleCode.CUSTOMER && !isCreationMode) &&
                        <>
                            <Grid item>
                                <Paper elevation={5}>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item px={2}>
                                            <Grid container alignItems="center">
                                                <ShoppingCartIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                                <Typography fontWeight="bolder" paddingLeft={2}>
                                                    <FormattedMessage id={messages.itemListCardFieldPurchased.id}/>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <ToggleButtonGroup
                                                value={itemFilter.isPurchased}
                                                exclusive
                                                onChange={(e, value) => handleFilterByBoolean(e, value, "isPurchased")}
                                                aria-label="is purchased state"
                                            >
                                                <ToggleButton value={true} aria-label="left aligned" color="success"
                                                              size="small">
                                                    <CheckCircleIcon/>
                                                </ToggleButton>
                                                <ToggleButton value={false} aria-label="centered" color="error"
                                                              size="small">
                                                    <UnpublishedIcon/>
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Paper elevation={5}>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item px={2}>
                                            <Grid container alignItems="center">
                                                <InventoryIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                                <Typography fontWeight="bolder" paddingLeft={2}>
                                                    <FormattedMessage id={messages.itemListCardFieldManagedInStock.id}/>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <ToggleButtonGroup
                                                value={itemFilter.isManagedInStock}
                                                exclusive
                                                onChange={(e, value) => handleFilterByBoolean(e, value, "isManagedInStock")}
                                                aria-label="is purchased state"
                                            >
                                                <ToggleButton value={true} aria-label="left aligned" color="success"
                                                              size="small">
                                                    <CheckCircleIcon/>
                                                </ToggleButton>
                                                <ToggleButton value={false} aria-label="centered" color="error"
                                                              size="small">
                                                    <UnpublishedIcon/>
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </>
                    }
                    {dataFormFields.geographicalOrigins.length > 0 && <Grid item>
                        <Accordion elevation={5} expanded={filterAccordionExpand.geographicalOrigin}
                                   onChange={() => handleToggleAccordionState("geographicalOrigin")}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>}>
                                <Grid container flexWrap="nowrap">
                                    <PublicIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                    <Typography fontWeight="bolder" whiteSpace="nowrap" paddingLeft={2}>
                                        <FormattedMessage id={messages.itemListFilterFieldGeographicalOriginTitle.id}/>
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <DividingHR subContent style={{margin: 0}}/>
                            <AccordionDetails sx={{maxHeight: "300px", overflow: "auto"}}>
                                <FormGroup>
                                    {dataFormFields.geographicalOrigins.map(go => {
                                        return (
                                            <FormControlLabel
                                                key={go.id}
                                                control={<Checkbox
                                                    onChange={(e) => handleFilterBy(e, "geographicalOriginIds")}
                                                    checked={itemFilter.geographicalOriginIds?.includes(go.id)}
                                                    name={String(go.id)}
                                                    sx={{color: "rgba(255, 82, 82, 1)", padding: 0}}/>}
                                                label={go.label ? go.label : ""}/>
                                        )
                                    })}
                                </FormGroup>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>}
                </Grid>
            </Box>
        </>
    )
}

export default ItemFilterAccordion;